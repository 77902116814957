import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import emptyInventory from '../../../../Images/add_image.png';
import axios from '../../../InstanceAxios/InstanceAxios'
import { useNavigate } from 'react-router-dom';
import { getShopInfo } from '../../../../utilities/StoredData';
import { useContext } from 'react';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { NotificationContext } from '../../../../Contexts/NotificationContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';

const StatusChip = styled(Typography)(({ theme }) => ({
    marginRight: '24px',
    border: '1px solid #021F3F',
    borderRadius: '6px',
    padding: '8px',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF'

    // [theme.breakpoints.down('md')]: {
    //     fontSize: '5.353vw',
    //     fontWeight: 400
    // },
}));


const InventoryProducts = () => {

    const info = getShopInfo()
    // console.log(info);

    const navigate = useNavigate();
    const [status, setStatus] = useState('all')
    const [chips, setChips] = useState({})
    const [products, setProducts] = useState([])
    const [display, setDisplay] = useState(true)
    const [totalStock, setTotalStock] = useState(0);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [willDeleteProduct, setWillDeleteProduct] = useState(null);
    const { shopDetails } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationContext);

    useEffect(() => {
        const url = `/products/getProductsV3/${info.shop_id}?status=${status}`
        const getData = async () => {
            const res = await axios.get(url)
            setProducts(res.data.data);
            setDisplay(false)
            setChips(res.data);
        }
        getData()
    }, [info.shop_id, status])

    //console.log(products);

    const handleRowClick = (params) => {
        // console.log(params.row.serial);
        navigate(`/inventory/${params}`)
    };

    const handleAddProductClick = () => {
        navigate('/inventory/add')
    }

    const deleteProduct = async () => {
        try {
            setOpenConfirmationModal(false);
            const id = willDeleteProduct?.product_id;
            const url = `/products/deleteProduct/${id}`;
            const res = await axios.put(url);
            if (res.data.status === 'S109') {
                setProducts((prev) => prev.filter((item) => item.product_id !== id));
                showNotification('success', 'Product Deleted Successfully');
            } else {
                showNotification('error', 'Failed to delete product');
            }
        } catch (error) {
            console.log(error);
            showNotification('error', 'Failed to delete product');
        } finally {
            setWillDeleteProduct(null);
        }
    };

    const handleDelete = (data) => {
        setWillDeleteProduct(data);
        setOpenConfirmationModal(true);
    };

    useEffect(() => {
        setTotalStock(Number(chips?.in_stock_count) + Number(chips?.out_of_stock_count) || 0);
    }, [chips]);

    return (
        <div>
            <ConfirmationModal
                open={openConfirmationModal}
                setOpen={setOpenConfirmationModal}
                handleAgree={deleteProduct}
                type='Product'
                title={willDeleteProduct?.product_name}
            />
            <Box sx={{ display: 'flex', marginLeft: '10px', marginBottom: '24px', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                    <StatusChip
                        onClick={() => setStatus('all')}
                        sx={status === 'all' && { backgroundColor: "#021F3F", color: '#FFFFFF' }}
                    >
                        <Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>All ({totalStock})</Typography>


                    </StatusChip>
                    <StatusChip
                        onClick={() => setStatus('in_stock')}
                        sx={status === 'in_stock' && { backgroundColor: "#021F3F", color: '#FFFFFF' }}
                    > <Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>In Stock ({chips?.in_stock_count})</Typography>

                    </StatusChip>
                    <StatusChip
                        onClick={() => setStatus('out_of_stock')}
                        sx={status === 'out_of_stock' && { backgroundColor: "#021F3F", color: '#FFFFFF' }}
                    ><Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>Out of Stock ({chips?.out_of_stock_count})</Typography>

                    </StatusChip>
                </Box>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={handleAddProductClick}
                    sx={{
                        backgroundColor: '#FF6550',
                        fontWeight: 500,
                        fontFamily: 'Figtree',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        marginRight: '24px',
                        textTransform: 'none',
                        '&:hover': { backgroundColor: '#ED5E4A', color: '#FFFFFF' }
                    }}>
                    <AddIcon sx={{ marginRight: '10px', width: 18 }} />
                    Add New Product
                </Button>

                {/* <StatusChip>All</StatusChip> */}
            </Box>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', backgroundColor: '#FFFFFF', paddingY: '24px', paddingRight: '24px' }}>
                <Box>
                    invoice search
                </Box>
                <Button
                    variant="contained"
                    onClick={handleAddProductClick}
                    sx={{ backgroundColor: '#FF6550', fontWeight: 500, fontFamily: 'Figtree', borderRadius: '8px', textTransform: 'none', '&:hover': { border: '1px solid #FF6550', backgroundColor: '#FF6550' } }}>
                    <AddIcon sx={{ marginRight: '10px' }} />
                    Add New Product
                </Button>
            </Box> */}
            {
                display && <Box> <CircularProgress sx={{ color: '#FF6550', marginTop: '15%' }} /></Box>
            }

            {
                !display && (products.length ? <Box>
                    <Grid container sx={{ marginY: '10px' }}>
                        <Grid item md={5}>
                            <Typography sx={{ textAlign: 'left', paddingLeft: '17px', fontWeight: 500, fontFamily: 'Figtree' }}>Product</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>Total In Stock</Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>Variant</Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>Price</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography sx={{ fontWeight: 500, fontFamily: 'Figtree' }}>Status</Typography>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>

                    </Grid>
                    <Box className='scroll' sx={{ overflowY: 'auto', height: '70vh' }}>
                        {
                            products.map(product => <Box
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    paddingX: '17px',
                                    '&:hover': {
                                        backgroundColor: '#F8F8F8'

                                    }
                                }}
                            >
                                <Grid container sx={{
                                    paddingY: '10px'

                                }}>
                                    <Grid
                                        item
                                        md={5}
                                        onClick={() => handleRowClick(product?.product_id)}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <img width='64px' height='64px' src={product?.product_images[0]?.image_url} alt='product Img' />
                                            <Typography sx={{ textAlign: 'left', marginLeft: '16px', fontWeight: 500, fontFamily: 'Figtree' }}>{product?.product_name}</Typography>
                                        </Box>

                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        onClick={() => handleRowClick(product?.product_id)}
                                    >
                                        <Typography sx={{ paddingTop: '20px', fontWeight: 500, fontFamily: 'Figtree' }}>{product?.variant_products?.length ? product?.stock[0]?.variant_stocks : product?.stock[0]?.number_of_stocks} {product?.unit_name}</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        onClick={() => handleRowClick(product?.product_id)}
                                    >
                                        <Typography sx={{ paddingTop: '20px', fontWeight: 500, fontFamily: 'Figtree' }}>{product?.variant_products?.length}</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        onClick={() => handleRowClick(product?.product_id)}
                                    >
                                        <Typography
                                            sx={{ paddingTop: '20px', marginLeft: '15px', fontWeight: 500, fontFamily: 'Figtree' }}
                                        >
                                            {shopDetails?.currencies?.title} {product?.unit_selling_price}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={2}
                                        onClick={() => handleRowClick(product?.product_id)}
                                    >
                                        {
                                            product?.publish_status === 'true' ? <Button
                                                disableElevation
                                                variant='contained'
                                                sx={{
                                                    marginTop: '18px',
                                                    marginLeft: '35px',
                                                    fontWeight: 500,
                                                    fontFamily: 'Figtree',
                                                    textTransform: 'none',
                                                    backgroundColor: '#00DD64',
                                                    '&:hover': { backgroundColor: '#00DD64' }
                                                }}
                                            >Live
                                            </Button> : <Button
                                                disableElevation
                                                sx={{
                                                    marginTop: '20px',
                                                    fontWeight: 500,
                                                    marginLeft: '30px',
                                                    fontFamily: 'Figtree',
                                                    color: '#FFFFFF',
                                                    textTransform: 'none',
                                                    backgroundColor: '#ED1C24',
                                                    '&:hover': { backgroundColor: '#ED1C24' }
                                                }}>Unpublished</Button>
                                        }
                                    </Grid>
                                    <Grid item md={1}>
                                        <DeleteOutlineIcon
                                            sx={{
                                                marginTop: '20px',
                                                marginLeft: '20px',
                                                color: '#021F3F',
                                            }}
                                            onClick={() => handleDelete(product)}
                                        />
                                    </Grid>
                                </Grid>
                                <hr style={{ border: '.5px solid #E6E6E6', margin: '0px' }} />
                            </Box>

                            )
                        }
                    </Box>




                </Box> : <Box sx={{ height: '70vh', display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <img height='230px' width='260px' src={emptyInventory} alt='empty inventory' />
                    <Typography sx={{ marginTop: '32px', color: '#95989A' }}>Your Inventory is empty, add a new product.</Typography>
                </Box>)

            }



        </div>
    );
};

export default InventoryProducts;