import React from 'react';

const MessengerInstructions = () => {
    return (
        <>
            <p>
                In order to use the Chat Plugin, you will need to have a published Facebook Page. If you do not have a Facebook Page, you can create one for free [here](https://www.facebook.com/pages/creation/?ref_type=pages_you_admin).
                <br /><br />
                Once you have your Facebook Page ready, you will be asked to login to Facebook. You can then follow the directions below.
                <br />
            </p>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    Go to your Facebook Page Settings by loggin in on [Facebook](http://www.facebook.com)
                </li>
                <li className='single-plugin-li'>
                    Click on about/settings, then check out for Page ID
                </li>
                <li className='single-plugin-li'>
                    Copy the page ID and paste it to your Plugin settings box to complete installation
                </li>
            </ul>
        </>
    );
};

export default MessengerInstructions;