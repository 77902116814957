import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { useState } from 'react';
import BonikLogo from '../../../Images/bonik-logo.png';
import OnboardSlider from '../OnboardSlider/OnboardSlider';
import axios from '../../InstanceAxios/InstanceAxios';
import { useNavigate } from 'react-router-dom';
import './OtpComponent.css';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { IS_VERIFIED, SIGN_UP } from '../../../utilities/MixpanelConst';
import { NotificationContext } from '../../../Contexts/NotificationContext';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)


const OtpComponent = ({ title, link, phone }) => {
    const [otp, setOtp] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const { showNotification } = useContext(NotificationContext);
    const navigate = useNavigate();

    const handleChange = (newValue) => {
        setOtp(newValue);
        if (newValue.length === 4) {
            const pattern = /^\d+$/;
            if (pattern.test(newValue)) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        } else {
            setIsDisabled(true);
        }
    }

    const verifyOtp = async (data) => {
        try {
            const response = await axios.post("/merchants/verifyOtp", {
                phone,
                otp
            });
            if (response.data.resetPasswordToken) {
                localStorage.setItem('resetPasswordToken', response.data.resetPasswordToken);

                //Mixpanel
                if (link.includes('/createStore')) {
                    //mixpanel.identify(jwt_decode(response.data.resetPasswordToken).merchant_id)
                    //mixpanel.track(IS_VERIFIED, "Yes")
                }
            }
            navigate(link);
        } catch (error) {
            if (error.response.status === 400) {
                showNotification("error", "Invalid OTP");
            }
            console.log(error);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        verifyOtp();
    }

    return (
        <Grid container>
            <Grid
                item
                lg={5}
                sx={{
                    overflowY: "auto",
                    height: "100vh",
                    '&::-webkit-scrollbar': {
                        display: "none"
                    }
                }}
            >
                <Box sx={{ margin: { md: "0 112px", xs: "0 35px" } }}>
                    <Box sx={{ marginTop: "50px" }}>
                        <img src={BonikLogo} alt="Bonik Logo" height={"40px"} />
                    </Box>
                    <Box sx={{ margin: "70px 0" }}>
                        <Typography variant="body1" sx={{ fontSize: "36px", fontFamily: "Figtree" }}>
                            {title}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "14px", fontFamily: "Figtree", color: "#95989A", marginTop: "16px" }}>
                            Enter the OTP sent to {phone}
                        </Typography>
                    </Box>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <MuiOtpInput value={otp} onChange={handleChange} sx={{ marginTop: "150px" }} />
                        <Button
                            type='submit'
                            variant="contained"
                            disableElevation
                            disabled={isDisabled}
                            sx={{
                                width: '100%',
                                backgroundColor: "#FF6550",
                                marginTop: "160px",
                                '&:hover': { backgroundColor: '#FF6550' }
                            }}
                        >
                            Verify & proceed
                        </Button>
                        <Typography variant="body1" sx={{ fontSize: "14px", fontFamily: "Figtree", margin: "24px 0 240px 0", textAlign: "center" }}>
                            Don't receive the OTP? <span style={{ color: "#FF6550" }} onClick={() => console.log("resend")}>Resend OTP</span>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={7} sx={{ display: { xs: 'none', lg: 'block' } }}>
                <OnboardSlider />
            </Grid>
        </Grid >
    );
};

export default OtpComponent;
