import React, { useContext } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import BkashLogo from '../../Images/bkash.png';
import SslLogo from '../../Images/ssl.png';
import { useNavigate } from 'react-router-dom';
import axios from '../InstanceAxios/InstanceAxios';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { AuthContext } from '../../Contexts/AuthContext';
import SwitchEnabledIcon from '../../Images/switch_enabled.svg';
import SwitchDisabledIcon from '../../Images/switch_disabled.svg';
import { NotificationContext } from '../../Contexts/NotificationContext';

const PaymentSettings = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [checked, setChecked] = useState(false);
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const { subscribedPlan } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationContext);

    const handleSetUp = () => {
        if (subscribedPlan.gateways.length && subscribedPlan.gateways[0].gateways.id === 3) {
            navigate('/plugins/ssl')
        } else {
            showNotification("error", `Your current plan doesn't have access to SSL Commerz. Please Upgrade`);
            navigate('/settings/subscription');
        }
    }

    const handleChange = async () => {
        try {
            const decoded = jwtDecode(localStorage.getItem('token'));
            let url;
            if (checked) {
                url = `/paymentMethod/addPaymentMethodToShop/${decoded.shop_id}`;
                const res = await axios.post(url, {
                    "payment_method_id": "1"
                });
                if (res.data.status === 'S136') {
                    showNotification("success", "Cash on delivery added successfully");
                } else {
                    showNotification("error", "Failed to add Cash on delivery");
                }
            } else {
                url = `/paymentMethod/removePaymentMethodFromShop/${decoded.shop_id}`;
                const res = await axios.delete(url, {
                    data: {
                        "payment_method_id": "1"
                    }
                });
                if (res.data.status === 'S135') {
                    showNotification("success", "Cash on delivery removed successfully");
                } else {
                    showNotification("error", "Failed to remove Cash on delivery");
                }
            }
            getPaymentMethodsByShopId();
        } catch (error) {
            console.log(error);
        }
    }

    const getPaymentMethodsByShopId = async () => {
        try {
            const decoded = jwtDecode(localStorage.getItem('token'));
            const url = `/paymentMethod/getAllPaymentMethodsByShop/${decoded.shop_id}`;
            const res = await axios.get(url);
            setPaymentMethods(res.data);
            const isChecked = res.data.findIndex((dt) => dt.id == 1);
            if (isChecked >= 0) {
                setChecked(true);
            }
            else {
                setChecked(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (step > 0)
            handleChange();
    }, [step]);

    useEffect(() => {
        getPaymentMethodsByShopId();
    }, []);

    return (
        <div className='delivery-bg'>
            <div className='single-delivery delivery-section'>
                <div>
                    <p className="delivery-title">
                        Cash on Delivery
                    </p>
                    <p className='delivery-info'>Manage your own delivery method</p>
                </div>
                <Box
                    component="img"
                    src={checked ? SwitchEnabledIcon : SwitchDisabledIcon}
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setChecked((prev) => !prev);
                        setStep((prev) => prev + 1);
                    }}
                />
            </div>
            <div className='single-delivery'>
                <p className="delivery-title">
                    Payment Method
                </p>
                <p className='delivery-info'>Manage your own delivery method our partners</p>
                {/* <div className='delivery-section' style={{ marginTop: "24px" }}>
                    <div className='delivery-paperfly-div'>
                        <img src={BkashLogo} height="48px" width="48px" alt="" />
                        <span>bKash</span>
                    </div>
                    <div>
                        <button className='set-up-btn'>Set Up</button>
                    </div>
                </div> */}
                <div className='delivery-section' style={{ marginTop: "24px" }}>
                    <div className='delivery-paperfly-div'>
                        <img src={SslLogo} height="48px" width="48px" alt="" />
                        <span>SSLCOMMERZ</span>
                    </div>
                    <div>
                        <button
                            className='set-up-btn'
                            onClick={handleSetUp}
                        >Set Up</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PaymentSettings;