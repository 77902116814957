import { Button, FormControl, InputAdornment, styled, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../InstanceAxios/InstanceAxios';
import './Profile.css';
import jwtDecode from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import VerifyPinModal from '../../components/VerifyPinModal/VerifyPinModal';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const Profile = () => {
    const [values, setValues] = useState({
        merchant_name: "",
        phone: "",
        email: "",
        trade_license_number: "",
        nid: "",
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const onSelectFile = e => {
        const selectedFile = e.target.files[0];
        setImage(selectedFile);
        setSelectedImage(URL.createObjectURL(selectedFile));
    }

    const handleCancelImage = () => {
        setSelectedImage(null);
    }

    const getMerchantDetails = async () => {
        try {
            const { merchant_id } = jwtDecode(localStorage.getItem('token'))
            const response = await axios.get(`/merchants/merchant/${merchant_id}`);
            const { data } = response;
            setValues({
                merchant_name: data.merchant_name,
                phone: data.phone ? `0${data.phone}` : "",
                email: data.email,
                trade_license_number: data.shops[0].trade_license_number,
                nid: data.nid,
            });
            setIsVerified(data.is_verified);
            setSelectedImage(data.img_url);
            if (data.phone) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const updateMerchantDetails = async () => {
        const formData = new FormData();
        formData.append('merchant_name', values.merchant_name);
        formData.append('phone', values.phone);
        formData.append('email', values.email);
        formData.append('trade_license_number', values.trade_license_number);
        formData.append('nid', values.nid);
        formData.append('image', image);
        try {
            const { merchant_id } = jwtDecode(localStorage.getItem('token'))
            const response = await axios.put(`/merchants/update/${merchant_id}`, formData);
            if (response.data.status === 'S121') {
                toast.success('Your information updated successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error('Failed to update your information', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to update your information', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setValues({
                merchant_name: "",
                phone: "",
                email: "",
                trade_license_number: "",
                nid: "",
            });
            setSelectedImage(null);
            setImage(null);
            getMerchantDetails();
        }
    }

    const handleVerifyPhoneNumber = async () => {
        try {
            const response = await axios.post("/merchants/sendOtp", {
                phone: values.phone,
            });
            toast.success('Otp Sent successfully', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
            setOpen(true);
        } catch (error) {
            console.log(error);
            toast.error('Failed to sent otp', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        }

    }

    useEffect(() => {
        if (open === false) {
            getMerchantDetails();
        }
    }, [open]);

    // useEffect(() => {
    //     getMerchantDetails();
    // }, []);



    return (
        <>
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            <VerifyPinModal
                open={open}
                setOpen={setOpen}
                phone={values.phone}
            />
            <Box className='profile-container'>
                <Box width='70%'>
                    <Typography
                        sx={{
                            color: "#95989A",
                            fontSize: "22px",
                            marginBottom: "18px"
                        }}
                    >
                        About Merchant
                    </Typography>
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        name='merchant_name'
                        value={values.merchant_name}
                        id="outlined-basic"
                        label="Merchant Name*"
                        variant="outlined"
                        placeholder='Enter your name'
                        onChange={handleChange}
                        fullWidth />
                    <FormControl sx={{ width: '100%' }}>
                        <CssTextField
                            label="Your Phone Number"
                            placeholder="01XXXXXXXXX"
                            variant="outlined"
                            sx={{ margin: "12px 0" }}
                            name='phone'
                            value={values.phone}
                            onChange={handleChange}
                            aria-describedby="number-text"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+88</InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        name='email'
                        value={values.email}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        placeholder='Enter your email'
                        onChange={handleChange}
                        fullWidth />
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        name='trade_license_number'
                        value={values.trade_license_number}
                        id="outlined-basic"
                        label="Business's Trade License No"
                        variant="outlined"
                        placeholder="Enter your business's Trade License No"
                        onChange={handleChange}
                        fullWidth />
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        name='nid'
                        value={values.nid}
                        id="outlined-basic"
                        label="NID No"
                        variant="outlined"
                        placeholder='Enter your NID No'
                        onChange={handleChange}
                        fullWidth />
                    <Typography
                        sx={{
                            color: "#95989A",
                            fontSize: "22px",
                            margin: "18px 0 24px 0"
                        }}
                    >
                        Merchant Photo
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            selectedImage ?
                                <Box height='200px'>
                                    <div>
                                        <img src={selectedImage} height='196px' width='196px' style={{ borderRadius: '4px', marginRight: '24px' }} alt='upload' />
                                    </div>
                                    <Box sx={{ marginTop: '-196px', marginLeft: '170px' }}>
                                        <CancelIcon onClick={() => { handleCancelImage() }} sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }} />
                                    </Box>
                                </Box>
                                :
                                <Box height='200px'>
                                    <label className='profile-addImage'>
                                        <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px', marginBottom: '5px' }} />
                                        Add Image
                                        <br />
                                        <input
                                            type="file"
                                            name="images"
                                            onChange={(e) => onSelectFile(e)}
                                            accept="image/png , image/jpeg, image/webp"
                                        />
                                    </label>
                                    <br />
                                    <input type="file" />
                                </Box>
                        }
                    </Box>
                </Box>
                {
                    !isVerified &&
                    <Box
                        sx={{
                            marginTop: '140px',
                            marginLeft: '20px'
                        }}
                    >
                        <Button
                            sx={{
                                color: '#FF6550',
                            }}
                            disabled={isDisabled}
                            onClick={handleVerifyPhoneNumber}
                        >
                            Verify Phone Number
                        </Button>
                    </Box>
                }
            </Box>
            <Box className='profile-btn-container'>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            margin: " 20px -23px 50px 0",
                            backgroundColor: '#FF6550',
                            '&:hover': {
                                borderColor: '#FF6550',
                                backgroundColor: '#FF6550'
                            }
                        }}
                        onClick={updateMerchantDetails}
                    // disabled={isDisabled}
                    >
                        update
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default Profile;