import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ value, setValue, editorHeight }) => {
    return (
        <ReactQuill
            style={{
                height: editorHeight || '200px'
            }}
            theme="snow"
            value={value}
            onChange={setValue}
            modules={{
                toolbar: [
                    [{
                        header: [
                            1,
                            2,
                            3,
                            false,
                        ]
                    }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { list: 'check' },
                        { indent: '-1' },
                        { indent: '+1' },
                    ],
                    ['link', 'image'],
                ],
            }}
        />
    );
};

export default Editor;