import React from 'react';

const SslInstructions = () => {
    return (
        <>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    Login to your SSLCOMMERZ admin panel or https://sslcommerz.com/ to get an account (special discount on one-time payment for Bonik users)
                </li>
                <li className='single-plugin-li'>
                    After getting the authenticated admin panel access, go to SETTINGS
                </li>
                <li className='single-plugin-li'>
                    Copy the 
                    <span className='single-plugin-bold'> Store ID, Store Password, Display Name, Description
                    </span>
                </li>
                <li className='single-plugin-li'>
                    Paste the credentials in plugin settings box and complete installation
                </li>
            </ul>
        </>
    );
};

export default SslInstructions;