import { Button, FormControlLabel, Grid, styled, Switch, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from '../InstanceAxios/InstanceAxios'
import jwtDecode from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from '../../Contexts/AuthContext';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const DomainSettingsAdd = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [domain, setDomain] = useState('');
    const { shopDetails, getShopDetails } = useContext(AuthContext);

    const handleSubmit = async () => {
        try {
            setIsDisabled(true);
            const data = jwtDecode(localStorage.getItem('token'));
            const url = `/merchants/updateShopDomain/${data.shop_id}`;
            const res = await axios.put(url, {
                shop_domain: domain
            });
            if (res.data.status === 'S126') {
                toast.success('Domain saved successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error('Failed to save domain', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDomain('');
            getShopDetails();
        }
    }

    useEffect(() => {
        if (domain.length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [domain]);

    useEffect(() => {
        setDomain(shopDetails.shop_domain || '');
    }, [shopDetails]);



    return (
        <>
            <div className='domain-add-container'>
                <ToastContainer
                    autoClose={5000}
                    closeOnClick />
                <p className='domain-add-title'>Domain Settings</p>
                <Grid container justifyContent='start' alignItems='center'>
                    <Grid></Grid>
                    <CssTextField
                        type="text"
                        sx={{ margin: "16px 0", width: "70%" }}
                        onChange={(e) => setDomain(e.target.value)}
                        value={domain}
                        name="inside_district"
                        id="outlined-basic"
                        label="Add domain link"
                        placeholder='Enter your exiting domain link here'
                        variant="outlined"
                    />
                    <Grid sx={{ margin: "0 0 0 auto" }}>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{
                                backgroundColor: '#FF6550',
                                textTransform: 'none',
                                '&:hover': {
                                    // borderColor: '#FF6550',
                                    backgroundColor: '#ED5E4A'
                                }
                            }}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                        >
                            Save Domain
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <p className='domain-add-details-title'>Point www CNAME record . . . </p>
            <div className='domain-home-continer'>
                <Grid container sx={{ textAlign: "left" }} className='domain-home-header'>
                    <Grid item md={4}>
                        Type
                    </Grid>
                    <Grid item md={4}>
                        Name
                    </Grid>
                    <Grid item md={4}>
                        Value
                    </Grid>
                </Grid>
                <div style={{ margin: "16px 0" }}>
                    <Grid container alignItems="center" sx={{ textAlign: "left" }} className='domain-home-data'>
                        <Grid item md={4}>
                            CNAME
                        </Grid>
                        <Grid item md={4}>
                            WWW
                        </Grid>
                        <Grid item md={4}>
                            bonik.store
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" sx={{ textAlign: "left" }} className='domain-home-data'>
                        <Grid item md={4}>
                            CNAME
                        </Grid>
                        <Grid item md={4}>
                            @
                        </Grid>
                        <Grid item md={4}>
                            bonik.store
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default DomainSettingsAdd;