import React, { useState } from 'react';
import './OnboardSlider.css';
import SliderImg1 from '../../../Images/slider_img_1.png';
import SliderImg2 from '../../../Images/slider_img_2.png';
import SliderImg3 from '../../../Images/slider_img_3.png';
import LeftArrow from './../../../Images/left_arrow.png';
import LeftArrowDisabled from './../../../Images/left_arrow_disabled.png';
import RightArrow from './../../../Images/right_arrow.png';
import RightArrowDisabled from './../../../Images/right_arrow_disabled.png';
import Eclipse from './../../../Images/ellipse.png';
import Rectangle from './../../../Images/Rectangle.png';

const OnboardSlider = () => {
    const [sliderData, setSliderData] = useState([
        {
            id: 0,
            img: SliderImg1,
            mainText: "Setup your online store in minutes",
            text: "Get free access by creating an account"
        },
        {
            id: 1,
            img: SliderImg2,
            mainText: "Manage your online business efficiently",
            text: "With a lightweight yet powerful platform"
        },
        {
            id: 2,
            img: SliderImg3,
            mainText: "Check in your business anytime, from any device",
            text: "Access store dashboard from web and mobile"
        }
    ]);
    const [currentSlide, setCurrentSlide] = useState(sliderData[0]);
    const handleLeftArrow = (data) => {
        setCurrentSlide(sliderData[(data.id) - 1]);
    }
    const handleRightArrow = (data) => {
        setCurrentSlide(sliderData[(data.id) + 1]);
    }

    return (
        <div className='bg-blue'>
            <div>
                <div className='img-div'>
                    <div className='back-layer'>
                        <img src={SliderImg1} alt="" />
                        <div className='mid-layer'>
                            <img src={SliderImg1} alt="" />
                            <div className='front-layer'>
                                <img style={{ width: "588px" }} src={currentSlide.img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='slider-text'>
                    <p className='slider-heading'>{currentSlide.mainText}</p>
                    <p className='slider-extra'>{currentSlide.text}</p>
                </div>
                <div className='slider-control'>
                    <div>
                        <button
                            className='arrow-button'
                            onClick={() => handleLeftArrow(currentSlide)}
                            disabled={currentSlide.id === 0}
                        >
                            <img src={currentSlide.id === 0 ? LeftArrowDisabled : LeftArrow} alt="" height={"32px"} />
                        </button>
                    </div>
                    <div className='mid-slider-div'>
                        <img src={currentSlide.id === 0 ? Rectangle : Eclipse} alt="" />
                        <img src={currentSlide.id === 1 ? Rectangle : Eclipse} alt="" className='mid-img-slider' />
                        <img src={currentSlide.id === 2 ? Rectangle : Eclipse} alt="" />
                    </div>
                    <div>
                        <button
                            className='arrow-button'
                            onClick={() => handleRightArrow(currentSlide)}
                            disabled={currentSlide.id === 2}
                        >
                            <img src={currentSlide.id === 2 ? RightArrowDisabled : RightArrow} alt="" height={"32px"} />
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default OnboardSlider;