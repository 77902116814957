import React, { createContext } from 'react';

export const ErrorContext = createContext();

const ErrorProvider = ({ children }) => {

    const [error, setError] = React.useState({});

    const ErrorInfo = {
        error,
        setError
    };

    return (
        <ErrorContext.Provider value={ErrorInfo}>
            {children}
        </ErrorContext.Provider>
    );
};

export default ErrorProvider;