import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, OutlinedInput, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { useState } from 'react';
import BonikLogo from '../../Images/bonik-logo.png';
import OnboardSlider from '../Shared/OnboardSlider/OnboardSlider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ResetPincode.css';
import { useEffect } from 'react';
import { ErrorContext } from '../../Contexts/ErrorContext';

const ResetPincode = () => {
    const [phone, setPhone] = useState("");
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [pinError, setPinError] = useState(null);
    const [confirmPinError, setConfirmPinError] = useState(null);
    const [mismatchError, setMismatchError] = useState(null);
    const navigate = useNavigate();
    const { error, setError } = useContext(ErrorContext);

    const resetPin = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/merchants/resetPassword`,
                {
                    merchant_id: phone,
                    password: pin
                },
                {
                    headers: {
                        'api_key': process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem('resetPasswordToken')}`
                    }
                }
            );
            if (response?.data?.status === "S120") {
                localStorage.removeItem("forgetPinPhone");
                localStorage.removeItem('resetPasswordToken');
                navigate("/login");
            } else {
                setError((prev) => ({ ...prev, resetPinPageError: 'Unable to reset password due to an unknown error. Please try again.' }));
            }
        } catch (error) {
            console.log(error);
            setError((prev) => ({ ...prev, resetPinPageError: 'Unable to reset password due to an unknown error. Please try again.' }));
            localStorage.removeItem('resetPasswordToken');
        }
    }

    const validatePin = () => {
        setMismatchError(null);
        if (pin.length !== 5 || confirmPin.length !== 5) {
            return;
        }
        if (pin === confirmPin) {
            setIsDisabled(false);
            setMismatchError(null);
        }
        else {
            setMismatchError("Pincode and Confirm Pincode Doesn't match");
            setIsDisabled(true);
        }
    }

    const handleChangePin = (e) => {
        setPin(e.target.value);
        const pattern = /^\d+$/;
        pattern.test(e.target.value);
        if (pattern.test(e.target.value)) {
            if (e.target.value.length !== 5) {
                setPinError("Pincode must be 5 digits long");
            } else {
                setPinError(null);
            }
        } else {
            setPinError("Only Numbers are Allowed");
        }
    }

    const handleChangeConfirmPin = (e) => {
        setConfirmPin(e.target.value);
        const pattern = /^\d+$/;
        if (pattern.test(e.target.value)) {
            if (e.target.value.length !== 5) {
                setConfirmPinError("Confirm Pincode must be 5 digits long");
            } else {
                setConfirmPinError(null);
            }
        } else {
            setConfirmPinError("Only Numbers are Allowed");
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsDisabled(true);
        resetPin();
    }

    useEffect(() => {
        validatePin();
    }, [pin, confirmPin]);

    useEffect(() => {
        if (localStorage.getItem("forgetPinPhone")) {
            setPhone(localStorage.getItem("forgetPinPhone"));
        } else {
            navigate("/forgotPincode");
        }
    }, []);

    return (
        <Grid container>
            <Grid item lg={5}>
                <Box sx={{ margin: { md: "0 112px", xs: "0 35px" } }}>
                    <Box sx={{ marginTop: "50px" }}>
                        <img src={BonikLogo} alt="Bonik Logo" height={"40px"} />
                    </Box>
                    <Box sx={{ margin: "70px 0" }}>
                        <Typography variant="body1" sx={{ fontSize: "36px", fontFamily: "Figtree" }}>
                            Reset pincode
                        </Typography>
                    </Box>
                    <Box
                        component="form"
                        noValidate autoComplete="off"
                        onSubmit={handleSubmit}
                        sx={{ marginTop: "48px" }}>
                        {
                            error?.resetPinPageError &&
                            <Box
                                sx={{
                                    backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                    margin: '8px 0',
                                    padding: "14px 16px",
                                    borderRadius: "8px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontFamily: "Figtree",
                                        color: "#ED1C24",
                                    }}
                                >
                                    {error?.resetPinPageError}
                                </Typography>
                            </Box>
                        }
                        <FormControl sx={{ width: '100%' }}>
                            <OutlinedInput
                                placeholder="Pincode"
                                sx={{ margin: "8px 0" }}
                                type="password"
                                value={pin}
                                onChange={(e) => handleChangePin(e)}
                                aria-describedby="pin"
                                error={pinError != null}
                            />
                            {pinError && <FormHelperText id="pin" error={pinError != null}>{pinError}</FormHelperText>}
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <OutlinedInput
                                placeholder="Confirm Pincode"
                                sx={{ margin: "8px 0" }}
                                type="password"
                                value={confirmPin}
                                onChange={(e) => handleChangeConfirmPin(e)}
                                aria-describedby="confirmPin"
                                error={confirmPinError != null}
                            />
                            {confirmPinError && <FormHelperText id="confirmPin" error={confirmPinError != null}>{confirmPinError}</FormHelperText>}
                        </FormControl>
                        {(!(pinError || confirmPinError)) && mismatchError && <FormHelperText error={mismatchError != null}>{mismatchError}</FormHelperText>}
                        <Button
                            type='submit'
                            variant="contained"
                            disableElevation
                            sx={{
                                width: '100%', backgroundColor: "#FF6550",
                                marginTop: "80px", '&:hover': { backgroundColor: '#FF6550' }
                            }}
                            disabled={isDisabled}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={7} sx={{ display: { xs: 'none', lg: 'block' } }}>
                <OnboardSlider />
            </Grid>
        </Grid>
    );
};

export default ResetPincode;