import React from 'react';

const WhatsappInstructions = () => {
    return (
        <>
            {/* whatsapp instructions will be added here */}
        </>
    );
};

export default WhatsappInstructions;