import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Radio, Typography } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { Box } from '@mui/system';
import React, { memo, useState } from 'react';
import CrossIcon from '../../../../Images/cross.png';
import Selected from '../../../../Images/selected.svg';

const VariantModal = ({ selectedImages, index, handleVariantImage, variantProd }) => {
    const images = selectedImages
    let selectedImage = []

    images.map(r => {
        selectedImage.push(false)

    })

    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState(selectedImage)

    const handleClick = () => {
        setOpen(!open)

    }
    let variantIndex = index


    const handleClickRadio = (index) => {
        let temp = [...checked];
        temp = temp.map(r =>
            false
        )
        temp[index] = true;
        setChecked(temp);
        handleVariantImage(index, variantIndex)
    }

    return (
        <>
            {
                variantProd[variantIndex].image_index !== -1 ? <img src={images[variantProd[variantIndex].image_index]} style={{ width: '40px', height: '40px' }} alt='variant img' /> : <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px' }} onClick={handleClick} />
            }


            <Dialog
                fullWidth

                open={open}
                onClose={handleClick}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            color: "#021F3F"
                        }}
                    >
                        Select a Picture
                    </Typography>
                    <Box
                        component="img"
                        src={CrossIcon}
                        sx={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                        }}
                        onClick={handleClick}
                    />
                </DialogTitle>
                <DialogContent sx={{ display: 'flex' }}>
                    {
                        images &&
                        images?.map((image, index) => {
                            return (
                                <Box
                                    onClick={() => handleClickRadio(index)}
                                    sx={{
                                        position: 'relative',
                                    }}
                                >
                                    {
                                        checked[index] &&
                                        <Box
                                            component="img"
                                            src={Selected}
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                zIndex: '2',
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                top: '0',
                                                padding: '5px',
                                                marginTop: '5px',
                                                marginLeft: '63px',
                                            }}
                                        />
                                    }
                                    <Box
                                        component="img"
                                        src={image}
                                        sx={{
                                            marginTop: '5px',
                                            width: '92px',
                                            height: '92px',
                                            borderRadius: '4px',
                                            marginRight: '24px',
                                            display: 'flex',
                                        }}
                                    >
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            zIndex: '1',
                                            bottom: '0',
                                            height: '90px',
                                            width: '90px',
                                            backgroundColor: 'rgba(102, 102, 102, 0.6)',
                                            borderRadius: '4px',
                                            opacity: checked[index] ? '1' : '0',
                                            border: '2px solid #021F3F',
                                            '&:hover': {
                                                opacity: '1',
                                            }
                                        }}
                                    />

                                </Box>

                            )




                        })
                    }
                    <Box sx={{ marginTop: '5px' }}>

                        {/* <Button
                            variant="outlined"
                            type='submit'
                            // onClick={ }
                            // onClick={handleAddTextFieldCOlor}

                            sx={{ border: '1px solid #FF6550', height: '32px', borderRadius: '8px', color: '#FF6550', textTransform: 'none', '&:hover': { border: '1px solid #FF6550', color: '#FF6550' } }}>

                            Select from device
                        </Button>
                        <br />
                        <input
                            type="file"
                            name="images"
                            // onChange={onSelectFile}
                            accept="image/png , image/jpeg, image/webp"
                        /> */}


                    </Box>


                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Close</Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default memo(VariantModal);