import React, { useContext } from 'react';
import './DeliverySettings.css';
import PaperflyLogo from '../../Images/paperfly-logo.png';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Contexts/AuthContext';
import { NotificationContext } from '../../Contexts/NotificationContext';

const DeliverySettingsHome = (props) => {
    const navigate = useNavigate();
    const { subscribedPlan } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationContext);

    const handleSetUp = () => {
        if (subscribedPlan.delivery === 'yes') {
            navigate('/plugins/paperfly');
        }
        else {
            showNotification("error", `Your current plan doesn't have access to Paperfly. Please Upgrade`);
            navigate('/settings/subscription');
        }
    }

    return (
        <div className='delivery-bg'>
            <div className='single-delivery delivery-section'>
                <div>
                    <p className="delivery-title">
                        Delivery Settings
                    </p>
                    <p className='delivery-info'>Manage your own delivery method</p>
                </div>
                <div>
                    <button
                        className='set-up-btn'
                        // style={{ '&:hover': { backgroundColor: '#ED5E4A', color: '#FFFFFF' } }}

                        onClick={() => props.changeComponent('next')}
                    >Set Up</button>
                </div>
            </div>
            <div className='single-delivery'>
                <p className="delivery-title">
                    DeliverySettings
                </p>
                <p className='delivery-info'>Manage your own delivery method our partners</p>
                <div className='delivery-section'>
                    <div className='delivery-paperfly-div'>
                        <img src={PaperflyLogo} height="48px" width="48px" alt="" />
                        <span>Paperfly</span>
                    </div>
                    <div>
                        <button
                            className='set-up-btn'
                            onClick={handleSetUp}
                        >Set Up</button>
                    </div>
                </div>
            </div>
            <p className='info' style={{ justifyContent: "start" }}>
                <AnnouncementIcon fontSize='small' sx={{ marginRight: "10px" }} />
                You can set only one delivery system at once
            </p>

        </div>
    );
};

export default DeliverySettingsHome;
