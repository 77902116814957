import React from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, CircularProgress } from '@mui/material'
import OrangeButton from '../Buttons/OrangeButton/OrangeButton';


const SelectCampaignProducts = ({ products, selectedProducts, handleClickedProduct, onClose }) => {
    return (
        <div>
            {
                products.length ?
                    products.map((product, index) =>
                        <Box
                            key={index}
                            onClick={() => handleClickedProduct(product.product_id)}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    paddingX: '24px',
                                    marginY: '16px'
                                }}
                            >
                                <Box
                                    sx={{ display: 'flex' }}>
                                    <img
                                        width='48px'
                                        style={{ marginRight: '16px' }}
                                        src={product.product_images[0]?.image_url}
                                        alt='product img'
                                    />
                                    <Box>
                                        <Typography
                                            sx={{ fontSize: '16px', color: '#021F3F' }}
                                        >
                                            {product.product_name}
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: '12px', color: '#95989A' }
                                            }>In Stock: {product.stock[0].number_of_stocks} Items
                                        </Typography>
                                    </Box>
                                </Box>
                                {
                                    products.length ?
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="payment_mode"
                                            value={selectedProducts.includes(product.product_id)}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                            />
                                        </RadioGroup>
                                        :
                                        <></>
                                }
                            </Box>
                            {
                                products.length - 1 !== index ?
                                    <hr style={{ border: '2px solid #F2F2F2', margin: "0 20px" }} />
                                    :
                                    <></>
                            }
                        </Box>
                    )
                    :
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '200px',
                        }}
                    >
                        <CircularProgress sx={{ color: '#FF6550' }} />
                    </Box>
            }
            <Box
                sx={{
                    margin: '24px 24px 0 24px'
                }}
            >
                <OrangeButton
                    title='Save'
                    handleOnClick={onClose}
                    sx={{
                        width: '100%',
                    }}
                />
            </Box>
        </div >
    );
};

export default SelectCampaignProducts;