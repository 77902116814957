import React from 'react';

const PaperflyFeatures = () => {
    return (
        <>
            <p>
                Paperfly is a one-stop logistics solutions provider company. Paperfly offers doorstep delivery services all around Bangladesh at the union level, along with warehousing and fulfilment facilities. They scan and track package movement at 25 steps and have a ‘near-to’ paperless supply chain. We are the first company in the country to have 100% of the field force using apps and updating status in real-time.
            </p>
            <p className='single-plugin-details-title'>
                Paperfly provides home delivery service in 64 districts and 491 Upazilas all over Bangladesh with its own set-up and owns field force.
            </p>
        </>
    );
};

export default PaperflyFeatures;