import React, { useState } from 'react';
import './CreateStorePage.css';
import BonikLogo from '../../Images/bonik-logo.png';
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, styled, TextField } from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import axios from '../InstanceAxios/InstanceAxios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';
import mixpanel from 'mixpanel-browser';
import { SHOP_CREATION } from '../../utilities/MixpanelConst';
import { getShopInfo } from '../../utilities/StoredData';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN )

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const CssInputLabel = styled(Select)({

    // '& label.Mui-focused': {
    //     color: '#021F3F',
    // },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#021F3F',
    },

});


const CreateStorePage = () => {
    const info = getShopInfo()
    const { setIsLoading, token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [currencyList, setCurrencyList] = useState([]);
    const [values, setValues] = useState({
        shop_name: "",
        shop_link: "",
        shop_category_id: "",
        currency_id: ""
    });
    const [isDisabled, setIsDisabled] = useState(true);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState("");

    const getAllCurrencies = async () => {
        try {
            const url = `/currencies/getAllCurrencies`;
            const res = await axios.get(url);
            setCurrencyList(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getShopCategories = async () => {
        try {
            const url = `/shopCategories/getShopCategories`;
            const res = await axios.get(url);
            setCategories(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (event) => {
        const oldValues = { ...values };
        oldValues[event.target.name] = event.target.value;
        setValues({ ...oldValues });
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const { merchant_id } = jwtDecode(localStorage.getItem('token'));
        const formData = {
            merchant_id: Number(merchant_id),
            shop_name: values.shop_name,
            shop_link: values.shop_link,
            shop_category_id: values.shop_category_id,
            currency_id: values.currency_id
        };

        const url = `/merchants/addShopV2`;
        setIsDisabled(true);
        try {
            const res = await axios.post(url, formData);
            if (res.data.accessToken) {
                localStorage.setItem('token', res.data.accessToken);
                setToken(res.data.accessToken);
                setIsLoading(false);

                //mixpanel
                mixpanel.identify(info?.merchant_id)
                mixpanel.track(SHOP_CREATION)
                mixpanel.people.append("Store Name", values.shop_name)
                mixpanel.people.append("Store Url", values.shop_link)
                mixpanel.people.append("Store Category", values.shop_category_id)

                navigate('/dashboard');
            } else {
                toast.error('Failed to create your store', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
                setIsLoading(false);
            }
        } catch (error) {
            if (error.response.status === 400) {
                setError("Shop link is already taken");
            } else {
                console.log(error);
                toast.error('Failed to create your store', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!values.shop_name.length || !values.shop_link.length || !values.shop_category_id) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [values.shop_name, values.shop_link, values.shop_category_id]);

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        getShopCategories();
        getAllCurrencies();
    }, []);

    return (
        <div className='bg-store '>
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            <div className="content">
                <img src={BonikLogo} height="64px" alt="" />
                <p className='title'>Enter your store details</p>
                <CssTextField
                    sx={{ margin: "10px 0" }}
                    onChange={(e) => handleChange(e)}
                    value={values.shop_name}
                    name="shop_name"
                    id="outlined-basic"
                    label="Store Name*"
                    placeholder='Enter your store name'
                    variant="outlined"
                    fullWidth />

                <CssTextField
                    error={error ? true : false}
                    sx={{ margin: "10px 0" }}
                    onChange={(e) => handleChange(e)}
                    value={values.shop_link}
                    name="shop_link"
                    label="Store Link*"
                    id="outlined-start-adornment"
                    helperText={error}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">www.bonik.store/</InputAdornment>,
                    }}
                    fullWidth
                />
                <FormControl fullWidth sx={{ margin: "10px 0" }}>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            "&.Mui-focused": {
                                color: "#021F3F"
                            }
                        }}
                    >Store Category*</InputLabel>
                    <CssInputLabel
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name='shop_category_id'
                        value={values.shop_category_id}
                        label="Store Category*"
                        placeholder='Choose store category'
                        onChange={(e) => handleChange(e)}
                    >
                        {categories.length && categories.map((category) =>
                            <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                        )}
                    </CssInputLabel>
                </FormControl>
                <FormControl fullWidth sx={{ margin: "10px 0" }}>
                    <InputLabel
                        id="currency-simple-select-label"
                        sx={{
                            "&.Mui-focused": {
                                color: "#021F3F"
                            }
                        }}
                    >Select Currency*</InputLabel>
                    <CssInputLabel
                        labelId="currency-simple-select-label"
                        id="currency-simple-select"
                        name='currency_id'
                        value={values.currency_id}
                        label="Select Currency*"
                        placeholder='Choose store currency'
                        onChange={(e) => handleChange(e)}
                    >
                        {currencyList.length && currencyList.map((currency) =>
                            <MenuItem
                                key={currency.id}
                                value={currency.id}
                            >
                                {currency.title}
                            </MenuItem>
                        )}
                    </CssInputLabel>
                </FormControl>
                <p className='info'><AnnouncementIcon fontSize='small' sx={{ marginRight: "10px" }} /> The store link can not be changed later. However, you can add custom domain anytime.</p>
                <Button
                    variant="contained"
                    sx={{
                        marginTop: "64px",
                        backgroundColor: '#FF6550',
                        '&:hover': {
                            borderColor: '#FF6550',
                            backgroundColor: '#FF6550'
                        }
                    }}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    Create Online Store
                </Button>
            </div>
        </div>
    );
};

export default CreateStorePage;