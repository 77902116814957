import React from 'react';

const AnalyticsInstructions = () => {
    return (
        <>
            <p>
                Here's how you can start using Google Analytics:
            </p>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    To start using Google Analytics, create an account on it or log in (if you have one)
                </li>
                <li className='single-plugin-li'>
                    Once logged in, click on
                    <span className='single-plugin-bold'> Admin </span>
                </li>
                <li className='single-plugin-li'>
                    Select the account from the menu in the
                    <span className='single-plugin-bold'> ACCOUNT </span> column.
                </li>
                <li className='single-plugin-li'>
                    Select the property from the menu in the
                    <span className='single-plugin-bold'> PROPERTY column </span>.
                </li>
                <li className='single-plugin-li'>
                    Under PROPERTY, click
                    <span className='single-plugin-bold'> Data Streams, </span>
                    then click on
                    <span className='single-plugin-bold'> Tracking Info </span>
                </li>
                <li className='single-plugin-li'>
                    Now, select
                    <span className='single-plugin-bold'> Tracking/Measurement Code </span>

                </li>
                <li className='single-plugin-li'>
                    Copy the Tracking ID displayed at the top of the page
                </li>
                <li className='single-plugin-li'>
                    Now, Install the Google Analytics plugin on Bonik
                </li>
                <li className='single-plugin-li'>
                    Click on
                    <span className='single-plugin-bold'> Settings </span>
                    and it’ll ask for Analytics Property ID
                </li>
                <li className='single-plugin-li'>
                    Paste the Tracking ID here to complete the installationion
                </li>
            </ul>
        </>
    );
};

export default AnalyticsInstructions;