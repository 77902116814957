import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import AddFeaturedProduct from '../ThemeSettings/AddFeaturedProduct';
import jwtDecode from 'jwt-decode';
import axios from '../InstanceAxios/InstanceAxios';
import { useEffect } from 'react';
import VerticalThreeDots from '../../Images/vertical_three_dots.png';
import { toast, ToastContainer } from 'react-toastify';

const ThemeFeaturedProducts = () => {
    const [isAdd, setIsAdd] = useState(0);
    const [featuredProducts, setFeaturedProducts] = useState([]);

    const getFeaturedProducts = async () => {
        try {
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const url = `/products/featured/all?shop_id=${shop_id}`;
            const res = await axios.get(url);
            const fp1 = res.data.Featured_Products.findIndex(fp => fp.fp_id === 1);
            const fp2 = res.data.Featured_Products.findIndex(fp => fp.fp_id === 2);
            const arr = [];
            if (fp1 >= 0) {
                arr.push(res.data.Featured_Products[fp1]);
            }
            if (fp2 >= 0) {
                arr.push(res.data.Featured_Products[fp2]);
            }
            setFeaturedProducts([...arr]);
        } catch (error) {
            console.log(error);
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleDelete = async (index, id) => {
        try {
            if (index == 0) {
                setAnchorEl2(null);
            } else {
                setAnchorEl(null);
            }
            const url = `/products/featured/deleteFeaturedProduct/${id}`;
            const res = await axios.delete(url);
            if (res.data.status === 'S124') {
                toast.success('Featured product deleted successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
                getFeaturedProducts();
            } else {
                toast.failed('Failed to delete featured product', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log(error);
            toast.failed('Failed to delete featured product', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    useEffect(() => {
        getFeaturedProducts();
    }, [isAdd]);

    return (
        <>
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            {!isAdd ?
                <div className='featured-container'>
                    <Typography
                        sx={{
                            color: "#021F3F",
                            fontSize: "22px",
                            textAlign: "left",
                            marginBottom: "24px"
                        }}>
                        Featured
                    </Typography>
                    <Grid container>
                        <Grid item md={6}>
                            <Box
                                sx={{
                                    border: "1px solid #95989A",
                                    borderRadius: "8px",
                                    padding: "15px",
                                    marginRight: "10px",

                                }}>
                                {
                                    featuredProducts[0]?.fp_id !== 1 ?
                                        <Box
                                            onClick={() => setIsAdd(1)}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}>
                                            <Typography
                                                sx={{
                                                    color: "#021F3F",
                                                    fontSize: "16px"
                                                }}
                                            >
                                                Add featured product 1
                                            </Typography>
                                            <AddIcon fontSize='small' sx={{ color: "#021F3F", }} />
                                        </Box>
                                        :
                                        <Box sx={{ textAlign: "left" }}>
                                            <img
                                                src={featuredProducts[0]?.img_url}
                                                alt=""
                                                style={{
                                                    maxWidth: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <Typography
                                                    sx={{
                                                        color: "#021F3F",
                                                        fontSize: "22px",
                                                        margin: "15px 0"
                                                    }}
                                                >
                                                    {featuredProducts[0]?.title}
                                                </Typography>
                                                <img
                                                    className='show-featured-option'
                                                    src={VerticalThreeDots}
                                                    alt=''
                                                    id="featured-one"
                                                    aria-controls={open2 ? 'featured-one' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open2 ? 'true' : undefined}
                                                    onClick={handleClick2}
                                                />
                                                <Menu
                                                    id="featured-one"
                                                    aria-labelledby="featured-one"
                                                    anchorEl={anchorEl2}
                                                    open={open2}
                                                    onClose={handleClose2}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => setIsAdd(1)}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => handleDelete(0, featuredProducts[0]?.id)}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                            <Typography
                                                sx={{
                                                    color: "#95989A",
                                                    fontSize: "16pxpx"

                                                }}
                                            >{featuredProducts[0]?.description}</Typography>
                                        </Box>
                                }

                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box
                                sx={{
                                    border: "1px solid #95989A",
                                    borderRadius: "8px",
                                    padding: "15px",
                                    marginLeft: "10px",

                                }}>
                                {
                                    featuredProducts[1]?.fp_id !== 2 ?
                                        <Box
                                            onClick={() => setIsAdd(2)}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}>
                                            <Typography
                                                sx={{
                                                    color: "#021F3F",
                                                    fontSize: "16px"
                                                }}
                                            >
                                                Add featured product 2
                                            </Typography>
                                            <AddIcon fontSize='small' sx={{ color: "#021F3F", }} />
                                        </Box>
                                        :
                                        <Box sx={{ textAlign: "left" }}>
                                            <img
                                                src={featuredProducts[1]?.img_url}
                                                alt=""
                                                style={{
                                                    maxWidth: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                                <Typography
                                                    sx={{
                                                        color: "#021F3F",
                                                        fontSize: "22px",
                                                        margin: "15px 0"
                                                    }}
                                                >
                                                    {featuredProducts[1]?.title}
                                                </Typography>
                                                <img
                                                    className='show-featured-option'
                                                    src={VerticalThreeDots}
                                                    alt=''
                                                    id="demo-positioned-button"
                                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                />
                                                <Menu
                                                    id="demo-positioned-menu"
                                                    aria-labelledby="demo-positioned-button"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => setIsAdd(2)}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => handleDelete(1, featuredProducts[1]?.id)}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                            <Typography
                                                sx={{
                                                    color: "#95989A",
                                                    fontSize: "16pxpx"

                                                }}
                                            >{featuredProducts[1]?.description}</Typography>
                                        </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid >
                </div >
                :
                <AddFeaturedProduct
                    isAdd={isAdd}
                    setIsAdd={setIsAdd}
                    featuredProducts={featuredProducts}
                />
            }
        </>
    );
};

export default ThemeFeaturedProducts;