import { Button } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SslDialouge from './SslDialougue';
import axios from '../InstanceAxios/InstanceAxios';
import jwtDecode from 'jwt-decode';
import SslLogo from '../../Images/ssl.png';
import SslFeatures from './SslFeatures';
import SslInstructions from './SslInstructions';

const PluginSsl = () => {
    const { shop_id } = jwtDecode(localStorage.getItem('token'));
    const [isShowDetails, setIsShowDetails] = useState(true);
    const [isOpenedDialouge, setIsOpenedDialouge] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    const selected = {
        padding: "8px 16px",
        backgroundColor: '#021F3F',
        border: '1px solid #021F3F',
        borderRadius: "6px",
        textTransform: 'none',
        fontFamily: 'figtree',
        '&:hover': {
            border: '1px solid #021F3F',
            borderColor: '#021F3F',
            backgroundColor: '#021F3F'
        }
    };
    const notSelected = {
        padding: "8px 16px",
        backgroundColor: 'white',
        border: '1px solid #021F3F',
        borderRadius: "6px",
        color: "#021F3F",
        textTransform: 'none',
        fontFamily: 'figtree',
        '&:hover': {
            border: '1px solid #021F3F',
            backgroundColor: 'white',
            color: "#021F3F"
        }
    };

    const handleInstall = () => {
        setIsOpenedDialouge(true);
    }

    const handleUninstall = async () => {
        try {
            const updatedData = {
                payment_method_id: "3"
            };
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const url = `/paymentMethod/removePaymentMethodFromShop/${shop_id}`;
            const res = await axios.delete(url, {
                data: updatedData
            });
            getSslData();
        } catch (error) {
            console.log(error);
        }
    }


    const getData = async () => {
        try {
            getSslData();
        } catch (error) {
            console.log(error);
        }
    }

    const getSslData = async () => {
        try {
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const url = `/paymentMethod/getAllPaymentMethodsByShop/${shop_id}`
            const res = await axios.get(url);
            const isInstalled = res.data.findIndex(dt => dt.id === 3);
            if (isInstalled > 0) {
                setData(res.data[isInstalled]);
            } else {
                setData({});
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        if (!isOpenedDialouge) {
            getData();
        }
    }, [isOpenedDialouge]);

    return (
        <div className='single-plugin-container scroll' style={{ overflowY: 'auto', height: '70vh' }}>
            <SslDialouge
                isOpenedDialouge={isOpenedDialouge}
                setIsOpenedDialouge={setIsOpenedDialouge}
                data={data}
            />
            <div className='single-plugin-header'>
                <div>
                    <img src={SslLogo} height='120px' width='120px' alt="" />
                </div>
                <div className='single-plugin-header-right'>
                    <p className='single-plugin-title'>SSL ecommerze</p>
                    {Object.keys(data).length == 0 || Object.values(data)[0] === null ?
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{
                                padding: "8px 16px",
                                backgroundColor: '#FF6550',
                                borderRadius: "6px",
                                textTransform: 'none',
                                fontFamily: 'figtree',
                                '&:hover': {

                                    backgroundColor: '#ED5E4A'
                                }
                            }}
                            onClick={handleInstall}
                        >
                            Install
                        </Button>
                        :
                        <span>
                            <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                    padding: "8px 16px",
                                    marginRight: "16px",
                                    backgroundColor: '#FF6550',
                                    borderRadius: "6px",
                                    textTransform: 'none',
                                    fontFamily: 'figtree',
                                    '&:hover': {

                                        backgroundColor: '#ED5E4A'
                                    }
                                }}
                                onClick={handleInstall}
                            >
                                Setting
                            </Button><Button
                                disableElevation
                                variant="outlined"
                                sx={{
                                    padding: "8px 16px",
                                    color: '#FF6550',
                                    border: '1px solid #FF6550',
                                    borderRadius: "6px",
                                    '&:hover': {
                                        border: '1px solid #FF6550',
                                        color: '#FF6550',
                                        backgroundColor: '#E8D9D7'
                                    }
                                }}
                                onClick={handleUninstall}
                            >
                                Uninstall
                            </Button>
                        </span>}
                </div>
            </div>
            <div className='single-plugin-btn-div'>
                <Button
                    disableElevation
                    variant="contained"
                    sx={isShowDetails ? selected : notSelected}
                    onClick={() => setIsShowDetails(true)}
                >
                    Details
                </Button>
                <Button
                    disableElevation
                    variant="contained"

                    sx={isShowDetails ? { marginLeft: "24px", ...notSelected } : { marginLeft: "24px", ...selected }}
                    onClick={() => setIsShowDetails(false)}
                >
                    Instruction
                </Button>
            </div>
            <div className='single-plugin-description'>
                {isShowDetails ? <SslFeatures /> : <SslInstructions />}
            </div>
        </div>
    );
};

export default PluginSsl;