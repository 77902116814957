import React from 'react';

const PixelFeatures = () => {
    return (
        <>
            <p>Facebook pixel is a piece of code that you put on your website that allows you to measure the effectiveness of your advertising by understanding the actions people take on your website. Examples of actions include adding an item to their shopping basket or making a purchase. You can then reach out to those customers again in the future through Facebook ads.
            </p>
            <p className='single-plugin-details-title'>Features</p>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    Make sure that your ads are shown to the right people. Find new customers or people who have visited a specific page or taken a desired action on your website.
                </li>
                <li className='single-plugin-li'>
                    Drive more sales. Set up automatic bidding to reach people who are more likely to take any action that you care about, such as making a purchase.
                </li>
                <li className='single-plugin-li'>
                    Measure the results of your ads. Better understand the impact of your ads by measuring what happens when people see them.
                </li>
            </ul>
        </>
    );
};

export default PixelFeatures;